<template>
  <div>
    <template>
      <!-- First Row -->
      <b-overlay :show="loadingData" variant="transparent" no-wrap />
      <b-card>
        <b-row>
          <h4 class="pl-1 pb-1">Numero de apuesta: {{ $route.params.id }}</h4>
          <b-table md="9" :items="dataItems" :fields="fields" class="scroll" responsive>
            <template #cell(reference)="data">
              <span class="text-muted mr-1">
                <b-button variant="outline-primary">{{ data.item.referenceId }}</b-button>
              </span>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount | currency({ symbol: "" }) }}
            </template>
            <template #cell(amountPrize)="data">
              {{ data.item.amountPrize | currency({ symbol: "" }) }}
            </template>
            <template #cell(amountRetired)="data">
              {{ (data.item.amountRetired * coef) | currency({ symbol: "" }) }}
            </template>
            <template #cell(createdAt)="data">
              {{ data.item.createdAt | moment }}
            </template>
            <template #cell(transaction_status)="data">
                <span
                  v-if="$route.params.status == 'bet'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #03a9f4; color:#FFFFFF;"
                >
                  {{ $t('status.bet') }}</span
                >
                <span
                  v-else-if="$route.params.status == 'return'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
                >
                  {{ $t('status.return') }}</span
                >
                <span
                  v-else-if="$route.params.status == 'bet_cancel'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.bet_cancel') }}</span
                >
                <span
                  v-else-if="$route.params.status == 'cashout'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.cashout') }}</span
                >
                <span
                  v-else-if="$route.params.status == 'lose'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.lose') }}</span
                >
                <span
                  v-else-if="$route.params.status == 'win'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
                >
                  {{ $t('status.win') }}</span
                >
                <span
                  v-else-if="$route.params.status == 'cancel'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.cancel') }}</span
                >
                <span
                  v-else-if="$route.params.status == 'rollback'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.rollback') }}</span
                >
              </template>
          </b-table>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <h3 class="pl-1 pb-1">{{ $t("labels.ticketdetail") }}</h3>
          <b-table md="9" :items="dataSelelections" :fields="detailFields" class="scroll" responsive>
            <!-- game -->
            <template #cell(game)="data">
              <span v-html="data.item.game"></span>
            </template>

          </b-table>
        </b-row>
        <div class="d-flex mt-2">
          <b-button type="button" variant="outline-secondary" to="/apps/reports/sports/ticket-sports-live/liveBetting">
            {{ $t("buttons.back") }}
          </b-button>
        </div>
      </b-card>
    </template>
  </div>
</template>

<script>
import router from "@/router";
import axios from "@axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BRow, BCol, BAlert, BLink, BButton, BOverlay } from "bootstrap-vue";
import Clipboard from "@/views/extensions/clipboard/Clipboard.vue";

export default {
  components: {
    BButton,
    Clipboard,
    BRow,
    BOverlay,
    BCol,
    BAlert,
    BLink,
  },
  data() {
    return {
      coef: 0,
      fields: [
        { label: "Nro Ticket", key: "reference" }, //reference
        { label: "Apuesta Id", key: "transactionID" }, //transactionID
        { label: "Usuario", key: "user[0].username" }, //user_from.userName
        { label: "Fecha Creado", key: "createdAt" }, //createdAt
        { label: "Status", key: "transaction_status" }, //transaction_status
        { label: "Monto Apostado", key: "amount" }, //amount
        { label: "Monto a Cobrar", key: "amountPrize" }
      ],
      detailFields: [
        { label: "Tipo", key: "type" },
        { label: "Seleccion", key: "selection" },
        { label: "Liga", key: "league" },
        { label: "Deporte", key: "sport" },
        { label: "Odds", key: "odds" },
        { label: "Fecha", key: "start" },
        { label: "Juego", key: "game" },
      ],
      dataItems: [],
      dataIdUser: [],
      rows: [],
      loadingData: false,
      dataSelelections: [],
    };
  },
  mounted() {
    /* this.getDataTransaccion() */
    this.getData();
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    currency: function (value) {
      return parseFloat(value).toFixed(2);
    },
  },
  methods: {
    async getData() {
      this.loadingData = true;
      /* this.dataIdUser = userId; */
      const VUE_APP_URL = process.env.VUE_APP_URL;
      try {
        const params = {
          transactionId: router.currentRoute.params.id,
          whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
        };

        const result = await axios.post(
          `${VUE_APP_URL}/betprime/report/ticket/details`,
          params
        );
        this.dataIdUser = router.currentRoute.params.userId;
        this.coef = result.data[0]?.coef;
        let ticketDetail = result.data[0]?.events.map((event) => {
          let date = moment.unix(event.start)
          return {
            type: event.odds[0]['[col_n]'],
            selection: event.odds[0]['[n]'],
            league: event.league,
            sport: event.sport,
            start: date.format('YYYY-MM-DD hh:mm:ss'),
            odds: event.odds[0]['[c]'],
            game: event.opp_1 + " <strong>VS</strong> " + event.opp_2,
          };
        });
        this.dataSelelections = ticketDetail || [];

        this.getDataTransaccion()
      } catch (error) {
        console.log(error.response);
      }
    },
    async getDataTransaccion() {

      try {
       /*  this.loadingData = true; */
        const VUE_APP_URL = process.env.VUE_APP_URL;
        const params = {
          whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          transactionId: this.$route.params.id,
          userId: this.dataIdUser
        };
        const result = await axios.post(
          `${VUE_APP_URL}/betprime/report/ticket/history`,
          params
        );
        console.log("result", result)
        this.dataItems = result.data.map((item) => {
          return {
            ...item,
            amountPrize: item.amount * this.coef,
          };
        });
        return result;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
  },
};
</script>

<style></style>
